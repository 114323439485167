<template>
  <div>
    <v-container>
      <h1 class="primary--text pl-0">
        {{ $t("navbar.navigation.downloadDetails") }}
      </h1>
      <Breadcrumb
        v-if="$vuetify.breakpoint.smAndUp"
        :items="breadcrumb"
        class="custom-breadcrumb"
      />
      <v-row>
        <v-col class="" cols="0" md="12">
          <v-divider class="my-5"></v-divider>
          <h2 v-if="order">Richiesta Download #{{ order.orderId }}</h2>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="" cols="0" md="12">
          <!-- <MenuNavigator class="menu-navigator" /> -->
          <v-divider class="my-5"></v-divider>
          <div
            v-if="
              order && (order.orderStatusId == 6 || order.orderStatusId == 8)
            "
          >
            <div v-if="order.orderInfos && order.orderInfos.zip_file_url">
              <v-btn
                color="primary"
                :href="order.orderInfos.zip_file_url"
                class="ma-2 download-btn"
                depressed
                >Download</v-btn
              >
            </div>
            <v-row v-else>
              <v-col cols="12" sm="6" md="4" class="a-center">
                <v-select
                  :items="urls"
                  v-model="selectedUrl"
                  outlined
                  hide-details
                  flat
                  dense
                  solo
                  :label="$t('common.select')"
                  item-text="label"
                  item-value="url"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <v-btn
                  :href="selectedUrl"
                  color="primary"
                  class="ma-2 download-btn"
                  depressed
                  :disabled="disableDownload"
                  >Download</v-btn
                >
              </v-col>
            </v-row>
          </div>
          <div v-if="order && order.orderStatusId == 11">
            <v-col cols="12" sm="6" md="3">
              Estrazione non disponibile
            </v-col>
          </div>
          <v-btn
            color="primary"
            class="justify-self-md-end my-6 my-sm-0 mx-2 mx-sm-10"
            outlined
            v-else
            @click="refreshOrder"
          >
            Refresh
          </v-btn>
          <div></div>
        </v-col>
      </v-row>

      <v-data-table
        :headers="headers"
        :items="itemList"
        item-key="itemId"
        hide-default-footer
        class="pt-6"
        :items-per-page="-1"
      >
        <template v-slot:[`item.mediaURLMedium`]="{ item }">
          <v-img
            :src="item.product.mediaURLMedium"
            contain
            :height="70"
            :width="70"
          ></v-img>
        </template>
      </v-data-table>
    </v-container>
  </div>
</template>
<script>
import Breadcrumb from "@/components/navigation/Breadcrumb.vue";
import OrderService from "~/service/orderService";
import { mapState } from "vuex";

export default {
  name: "DownloadDetails",
  components: { Breadcrumb },
  computed: {
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    urls() {
      if (this.order) {
        let urls = [];
        console.log("TEST", this.order.orderInfos);
        for (const property in this.order.orderInfos) {
          if (property.startsWith("zip_file_url_part")) {
            let index = this.order.orderInfos[property].lastIndexOf("_");
            console.log(
              this.order.orderInfos[property]
                .substring(index + 1)
                .replace(".zip", "")
            );
            urls.push({
              url: this.order.orderInfos[property],
              label:
                "Part " +
                this.order.orderInfos[property]
                  .substring(index + 1)
                  .replace(".zip", "")
            });
          }
        }
        return urls;
      } else return [];
    },
    disableDownload() {
      return this.selectedUrl === null ? true : false;
    }
  },
  data() {
    return {
      order: null,
      itemList: [],
      selectedUrl: null,
      breadcrumb: [
        {
          text: "Home",
          disabled: false,
          to: "/"
        },
        {
          text: "Profilo",
          disabled: false,
          to: "/profile/profile-update"
        },
        {
          text: "Richieste di download",
          disabled: false,
          exact: true,
          to: "/profile/orders"
        },
        {
          text: "Dettaglio",
          disabled: true,
          href: "/"
        }
      ],
      sortOptions: [
        {
          value: "alf_asc",
          text: "A-Z"
        },
        {
          value: "alf_dsc",
          text: "Z-A"
        }
      ],
      sortFilter: null,
      headers: [
        { text: "", value: "mediaURLMedium", sortable: false },
        { text: "GTIN", value: "product.barcode", sortable: false },
        { text: "Azienda", value: "product.shortDescr", sortable: false },
        { text: "Brand", value: "product.shortDescr", sortable: false },
        { text: "Articolo", value: "product.name", sortable: false }
      ]
    };
  },
  methods: {
    clicked(item) {
      console.log(item);
    },
    handleItems(suborders, itemList) {
      for (var s = 0; s < suborders.length; s++) {
        var suborder = suborders[s];
        for (var i = 0; i < suborder.suborderItems.length; i++) {
          var item = suborder.suborderItems[i];
          if (!item.orderItemReplacedId) {
            var alternative = null;
            var type = "ordered";
            for (var j = 0; j < suborder.suborderItems.length; j++) {
              var itemAlternative = suborder.suborderItems[j];
              if (
                itemAlternative.orderItemReplacedId &&
                item.orderItemId == itemAlternative.orderItemReplacedId
              ) {
                alternative = itemAlternative;
                type = "replaced";
                break;
              }
            }
            itemList.push(this.handleOrderItem(item, type));
            if (alternative) {
              itemList.push(
                this.handleOrderItem(itemAlternative, "alternative")
              );
            }
          }
        }
      }
    },
    handleOrderItem(orderItem, type) {
      var q2 =
        (orderItem.deliveredQuantity > 0
          ? orderItem.deliveredQuantity
          : orderItem.deliveredWeight) || 0;

      orderItem.deliveredType =
        q2 == 0 &&
        this.order.orderStatusId < 10 &&
        this.order.orderStatusId > 13
          ? "replaced"
          : type;

      orderItem.deliveredItem = {
        product: orderItem.product,
        quantity: orderItem.deliveredQuantity,
        weight: orderItem.deliveredWeight
      };

      return orderItem;
    },
    async refreshOrder() {
      let vm = this;
      vm.order = await OrderService.getOrder(this.$route.params.orderId, true);
    }
  },
  async created() {
    this.order = await OrderService.getOrder(this.$route.params.orderId, true);
    this.handleItems(this.order.suborders, this.itemList);
    console.log(this.order.suborders);
  },
  metaInfo() {
    return {
      title: this.order
        ? this.$t("navbar.navigation.downloadDetails") +
          " #" +
          this.order.orderId
        : this.$t("navbar.navigation.downloadDetails")
    };
  }
};
</script>
<style scoped>
.download-btn {
  width: 180px !important;
}
.a-center {
  align-self: center;
}
.custom-breadcrumb {
  margin-left: -15px;
}
</style>
